export const environment = {
  otm: 'tkti',
  production: true,
  http_auth: 'JdI4VNf4SIG3VBgEWSUNF',
  otm_name: "Ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    // pvorey
    apiKey: 'AIzaSyCdXdbQrCOcdXI183VIcmetut_dCKG5dOU',
    authDomain: 'fjsti-c50ff.firebaseapp.com',
    projectId: 'fjsti-c50ff',
    storageBucket: 'fjsti-c50ff.appspot.com',
    messagingSenderId: '382705767044',
    appId: '1:382705767044:web:48527a1aeb7e8674c4e8d1',
    measurementId: 'G-L4NGYJKBMN',
  },
  http: {
    socket: {
      path: 'ws://ferpi.tsnqb.uz/socket.io',
    },
    editArea: {
      path: 'https://kpi.tkti.uz/api/system/area',
    },
    addArea: {
      path: 'https://kpi.tkti.uz/api/system/area',
    },
    deleteArea: {
      path: 'https://kpi.tkti.uz/api/system/area',
    },
    removeDepartment: {
      path: 'https://kpi.tkti.uz/api/system/department',
    },
    editDepartment: {
      path: 'https://kpi.tkti.uz/api/system/department',
    },
    getDepartmentAArea: {
      path: 'https://kpi.tkti.uz/api/system/getAll',
    },
    addDepartment: {
      path: 'https://kpi.tkti.uz/api/system/department',
    },
    userRegister: {
      path: 'https://kpi.tkti.uz/api/auth/registration',
    },
    updateIndex: {
      path: 'https://kpi.tkti.uz/api/index/update',
    },
    rateIndex: {
      path: 'https://kpi.tkti.uz/api/index/rate',
    },
    recoverPass: {
      path: 'https://kpi.tkti.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    setRole: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://kpi.tkti.uz/api/users/all',
    },
    updateUser: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    login: {
      path: 'https://kpi.tkti.uz/api/auth/login',
    },
    autologin: {
      path: 'https://kpi.tkti.uz/api/auth/autologin',
    },
    get: {
      path: 'https://kpi.tkti.uz/db/2024/get_data.php',
      file: 'pvorey.txt',
    },
    delete_only_inform_file: {
      path: 'https://kpi.tkti.uz/db/2024/delete_only_inform_file.php',
    },
    getFieldsInform: {
      path: 'https://kpi.tkti.uz/db/2024/get_fields_inform.php',
    },
    setInc: {
      path: 'https://kpi.tkti.uz/db/2024/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://kpi.tkti.uz/db/2024/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://kpi.tkti.uz/db/2024/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://kpi.tkti.uz/db/2024/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://kpi.tkti.uz/db/2024/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://kpi.tkti.uz/db/2024/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://kpi.tkti.uz/db/2024/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://kpi.tkti.uz/db/2024/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://kpi.tkti.uz/db/2024/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://kpi.tkti.uz/db/2024/create_announce.php',
    },
    getAnnounces: {
      path: 'https://kpi.tkti.uz/db/2024/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://kpi.tkti.uz/db/2024/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://kpi.tkti.uz/db/2024/edit_user_account.php',
    },
    createIndex: {
      path: 'https://kpi.tkti.uz/db/2024/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Neft va gaz texnologiyalar',
      kafedralar: [
        'Sellyuloza va yog‘ochsozlik texnologiyasi',
        'Neft va gazni qayta ishlash kimyoviy texnologiyasi',
        'Fizika va energetika',
        'Asosiy organik sintez',
      ],
    },
    {
      fakultet: 'Oziq - ovqat va vinochilik texnologiyalar',
      kafedralar: [
        'Oziq-ovqat va parfyumeriya-kosmetika mahsulotlari texnologiyasi',
        'Oziq-ovqat mahsulotlari sifati va xavfsizligi',
        'Biotexnologiya',
        'Enologiya va bijg‘ish mahsulotlari texnologiyasi',
      ],
    },
    {
      fakultet: 'Kimyoviy injinering',
      kafedralar: [
        'Texnologik mashina va jihozlari',
        'Yuqori molekulyar birikmalar va plastmassalar texnologiyasi',
        'Noorganik moddalar kimyoviy texnologiyalari',
        'Silikat materiallar va nodir, kamyob metallar texnologiyasi',
      ],
    },
    {
      fakultet: 'Sanoat muhandisligi va menejmenti',
      kafedralar: [
        'Avtomatika va raqamli boshqaruv',
        'Muhandislik asoslari va mexanika',
        'Sanoat iqtisodiyoti va menejmenti',
        'Sanoat ekologiyasi',
      ],
    },
    {
      fakultet: 'Fakultetlararo',
      kafedralar: [
        'Tillar',
        'Oliy matematika',
        'Kimyo',
        'Ijtimoiy - siyosiya fanlar',
      ],
    },
  ],
  grastud: 556,
  fakultet: [
    'Neft va gaz texnologiyalar',
    'Oziq - ovqat va vinochilik texnologiyalar',
    'Kimyoviy injinering',
    'Sanoat muhandisligi va menejmenti',
    'Fakultetlararo',
  ],
  kafedra: [
    'Sellyuloza va yog‘ochsozlik texnologiyasi',
    'Neft va gazni qayta ishlash kimyoviy texnologiyasi',
    'Fizika va energetika',
    'Asosiy organik sintez',
    'Oziq-ovqat va parfyumeriya-kosmetika mahsulotlari texnologiyasi',
    'Oziq-ovqat mahsulotlari sifati va xavfsizligi',
    'Biotexnologiya',
    'Enologiya va bijg‘ish mahsulotlari texnologiyasi',
    'Texnologik mashina va jihozlari',
    'Yuqori molekulyar birikmalar va plastmassalar texnologiyasi',
    'Noorganik moddalar kimyoviy texnologiyalari',
    'Silikat materiallar va nodir, kamyob metallar texnologiyasi',
    'Avtomatika va raqamli boshqaruv',
    'Muhandislik asoslari va mexanika',
    'Sanoat iqtisodiyoti va menejmenti',
    'Sanoat ekologiyasi',
    'Tillar',
    'Oliy matematika',
    'Kimyo',
    'Ijtimoiy - siyosiya fanlar',
  ],
};

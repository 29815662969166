/* tslint:disable:whitespace */
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/service/data.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { environment } from 'src/environments/environment.prod';
import { ExcelService } from 'src/app/shared/service/excel.service';
import * as XLSX from 'xlsx';
import { GoogleLinkRowComponent } from './render/google-link.component';

interface tmp {
  indexId: string;
  loading: boolean;
  tableLoading: boolean;
  change_link?: boolean;
}
@Component({
  selector: 'app-list-teachers',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListTeachersComponent implements OnInit {
  public user_list = [];
  tmp: tmp = {
    indexId: '',
    loading: true,
    tableLoading: true,
    change_link: true,
  };
  //   tmp: tmp = {
  //   indexId: '',
  //   tableLoading: true,
  //   change_link: true,
  // }
  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<a class="table_del_default_icon"></a>',
    },
    pager: {
      perPage: 10,
      display: true,
    },
    edit: {
      editButtonContent: 'EDIT ',
      saveButtonContent: 'SAVE ',
      cancelButtonContent: 'CANCEL ',
      confirmSave: true,
    },
    actions: {
      add: false,
      edit: this.dataService.user.roles.some(
        (r) => r === 'ADMIN' || r === 'SUPERADMIN'
      ),
      delete: this.dataService.user.roles.some(
        (r) => r === 'ADMIN' || r === 'SUPERADMIN'
      ),
      position: 'right',
    },
    columns: {
      // avatar: {
      //   title: 'Avatar',
      //   type: 'html'
      // },
      numbering: {
        title: 'Raqamlash',
      },
      lname: {
        title: 'Familiyasi',
        // editable: false
      },

      fname: {
        title: 'Ismi',
        // editable: false
      },
      patronymic: {
        title: 'Sharifi',
        // editable: false
      },
      email: {
        title: 'Email',
        editable: false,
      },
      born: {
        title: 'Tug‘ilgan yili',
      },
      phone: {
        title: 'Telefon raqami',
      },
      google_link: {
        title: 'Google scholar',
        type: 'custom',
        renderComponent: GoogleLinkRowComponent,
      },
      rank: {
        title: 'Lavozimi',
        editor: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: [
              { value: 'empty', title: '' },
              { value: 'Professor', title: 'Professor' },
              { value: 'Dotsent', title: 'Dotsent' },
              { value: 'Katta o‘qituvchi', title: 'Katta o‘qituvchi' },
              { value: 'Assistent', title: 'Assistent' },
              { value: 'Kafedra mudiri', title: 'Kafedra mudiri' },
            ],
          },
        },
      },
      wagerate: {
        title: 'Stavkasi',
        editor: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: [
              { value: 'empty', title: '' },
              { value: '1.5', title: '1.5' },
              { value: '1.25', title: '1.25' },
              { value: '1', title: '1' },
              { value: '0.75', title: '0.75' },
              { value: '0.5', title: '0.5' },
              { value: '0.25', title: '0.25' },
            ],
          },
        },
      },
      academic_degree: {
        title: 'Ilmiy darajasi',
        editor: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: [
              { value: 'empty', title: '' },
              { value: 'Fan doktori', title: 'Fan doktori' },
              { value: 'Fan nomzodi', title: 'Fan nomzodi' },
              { value: 'PhD', title: 'PhD' },
              { value: 'DSc', title: 'DSc' },
            ],
          },
        },
      },
      academic_title: {
        title: 'Ilmiy unvoni',
        editor: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: [
              { value: 'empty', title: '' },
              { value: 'Dotsent', title: 'Dotsent' },
              { value: 'Professor', title: 'Professor' },
            ],
          },
        },
      },
      gender: {
        title: 'Jinsi',
      },
      code_specialty: {
        title: 'Ilmiy faoliyat olib borayotgan ixtisoslik shifri',
      },
      year_of_employment: {
        title: 'Ishga kirgan yili',
      },
      year_started_teaching: {
        title: 'Pedagogik faoliyatini boshlagan yil',
      },
      state_type: {
        title: 'Shtat turi',
        editor: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: [
              { value: 'empty', title: '' },
              { value: 'Asosiy', title: 'Asosiy' },
              { value: 'Ichki o‘rindosh', title: 'Ichki o‘rindosh' },
              { value: 'Tashqi o‘rindosh', title: 'Tashqi o‘rindosh' },
              { value: 'Soatbay', title: 'Soatbay' },
            ],
          },
        },
      },
      fakul: {
        title: 'Fakultet',
        editor: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: [],
          },
        },
      },
      kafed: {
        title: 'Kafedra',
        editor: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: [],
          },
        },
      },
      google_not_working_for_table: {
        title: 'Google scholar yangilash holati',
      },
      pvo_status_ng2: {
        title: 'O‘qituvchi holati',
        editor: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: [
              { value: 'Ishlayapti', title: 'Ishlayapti' },
              { value: 'Ishlamayapti', title: 'Ishlamayapti' },
            ],
          },
        },
      },
      added_id: {
        title: 'Raqami',
      },
    },
  };
  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    private excelService: ExcelService
  ) {
    dataService.pvoKaf$.subscribe(async (res) => {
      console.log('pvoKaf ', res);
      res.map((pvo, index) => {
        pvo['wagerate'] = pvo['wagerate']
          ? pvo['wagerate'].replace(',', '.')
          : '';
        pvo['numbering'] = index + 1;
        pvo['pvo_status_ng2'] =
          !pvo['pvo_status'] ||
          (pvo['pvo_status'] && pvo['pvo_status'] === 'working')
            ? 'Ishlayapti'
            : 'Ishlamayapti';
        pvo['google_not_working_for_table'] = !pvo['google_update_not_working']
          ? 'Ishlamayapti'
          : '';
        this.user_list.push(pvo);
      });
      // this.user_list = await res;
      this.tmp.loading = false;
    });
    this.genSettings();
  }
  changePerPage(event) {
    this.tmp.loading = true;
    this.settings.pager.perPage = parseInt(event.target.value);
    setTimeout(() => {
      this.tmp.loading = false;
    }, 1000);
  }
  genSettings() {
    environment.tizim.forEach((item) => {
      this.settings.columns.fakul.editor.config.list.push({
        value: item.fakultet,
        title: item.fakultet,
      });
      item.kafedralar.forEach((kaf) => {
        this.settings.columns.kafed.editor.config.list.push({
          value: kaf,
          title: kaf,
        });
      });
    });
  }

  ngOnInit() {}
  onEditConfirm(event) {
    var data = event.newData;
    console.log(data);
    var newData = {
      fname: data['fname'],
      lname: data['lname'],
      patronymic: data['patronymic'],
      email: data['email'],
      academic_title: data['academic_title'],
      born: data['born'],
      rank: data['rank'],
      state_type: data['state_type'],
      wagerate: data['wagerate'],
      phone: data['phone'],
      google_link: data['google_link'],
      fakul: data['fakul'],
      kafed: data['kafed'],
      pvo_status:
        data['pvo_status_ng2'] === 'Ishlayapti' ? 'working' : 'notworking',
    };
    this.httpService.editUserAccaunt(data['added_id'], newData).subscribe(
      (res: any) => {
        console.log('res', res);
        if (res.status === 200) {
          alert("Ma'lumotlar muvaffaqiyatli yangilandi!");
        } else {
          alert("Nimadur hato ketti yana bir bor urinib ko'ring!");
        }
        // console.log('result ', res)
        event.confirm.resolve();
      },
      (error) => {
        alert(
          "Nimadur hato ketti yana bir bor urinib ko'ring! Error: " +
            JSON.stringify(error)
        );
      }
    );
    // console.log(newData)
  }
  fileName = 'ExcelSheet.xlsx';
  exportexcel(): void {
    /* table id is passed over here */
    this.tmp.loading = true;
    this.settings.pager.display = false;
    setTimeout(() => {
      this.tmp.loading = false;
      setTimeout(() => {
        // this.excelService.exportAsExcelFile(document.getElementById('excelTable').children[0]);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
          document.getElementById('excelTable').children[0]
        );

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, this.fileName);
      }, 1000);
    }, 1000);
  }
  onDeleteConfirm(event) {
    this.httpService.removePvo(event.data.added_id).subscribe(
      (result: any) => {
        if (result.status === 200) {
          alert('Foydalanuvchi muvaffaqiyatli ochirildi!');
          event.confirm.resolve();
        } else {
          alert("Nimadur hato ketti yana bir bor urinib ko'ring!");
        }
      },
      (error) => {
        alert("Nimadur hato ketti yana bir bor urinib ko'ring!");
        console.error(error);
      }
    );
  }
}
